import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a className="sel" href="/poradnik/">
                    Poradnik
                  </a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/poradnik/" className="sel">
              {" "}
              &gt; Poradnik
            </a>
          </div>
          <div
            id="page_content"
            style={{
              "padding-top": "0px",
            }}
          >
            <h1 className="homepage-heading">
              <img src="/kcfinder/uploads/files/box/headingp.png" />
            </h1>
            <div id="article_sort">
              <div className="category_switch">
                <span className="men" />
                <span className="girl" />
                <div className="filter" data-filter=".cat_1" data-hash="katar">
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/produkty/GettyImages-1007965142.jpg")',
                    }}
                  >
                    <span className="mask" />
                  </span>
                  <span className="name">Katar</span>
                </div>
                <div
                  className="filter"
                  data-filter=".cat_2"
                  data-hash="przeziebienie"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/produkty/shutterstock_571003300.jpg")',
                    }}
                  >
                    <span className="mask" />
                  </span>
                  <span className="name">Przeziębienie</span>
                </div>
                <div className="filter" data-filter=".cat_3" data-hash="dzieci">
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_105321398.jpg")',
                    }}
                  >
                    <span className="mask" />
                  </span>
                  <span className="name">Dzieci</span>
                </div>
              </div>
              <div className="wysiwyg">
                <p
                  style={{
                    "-webkit-text-align": "center",
                    "text-align": "center",
                    "font-wieght": "700",
                    "font-size": "18px",
                  }}
                >
                  Przeczytaj o dolegliwościach i dowiedz się, jak się przed nimi
                  chronić.
                </p>
              </div>
              <div id="article_list">
                <a
                  href="/poradnik/katar/jak_prawidlowo_oczyszczac_nos_podczas_kataru"
                  className="box mix cat_1"
                  data-myorder={0}
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg")',
                    }}
                  />
                  <span className="name">
                    Jak prawidłowo oczyszczać nos podczas kataru?
                  </span>
                </a>
                <a
                  href="/poradnik/katar/poznaj_rodzaje_kataru"
                  className="box mix cat_1"
                  data-myorder={1}
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg")',
                    }}
                  />
                  <span className="name">Poznaj rodzaje kataru</span>
                </a>
                <a
                  href="/poradnik/przeziebienie/domowe_sposoby_na_walke_z_przeziebieniem"
                  className="box mix cat_2"
                  data-myorder={2}
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/GettyImages-1030072086.jpg")',
                    }}
                  />
                  <span className="name">
                    Domowe sposoby na walkę z przeziębieniem
                  </span>
                </a>
                <a
                  href="/poradnik/katar/podejmij_szybka_walke_z_katarem"
                  className="box mix cat_1"
                  data-myorder={3}
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_152527238.jpg")',
                    }}
                  />
                  <span className="name">Podejmij szybką walkę z katarem</span>
                </a>
                <a
                  href="/poradnik/dzieci/zatkany_nos_u_dziecka"
                  className="box mix cat_3"
                  data-myorder={4}
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/GettyImages-135538267.jpg")',
                    }}
                  />
                  <span className="name">Zatkany nos u dziecka</span>
                </a>
                <a
                  href="/poradnik/dzieci/odpornosc_dziecka_-_zadbaj_i_zwiekszaj"
                  className="box mix cat_3"
                  data-myorder={5}
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_1668728821.jpg")',
                    }}
                  />
                  <span className="name">
                    Odporność dziecka - zadbaj i zwiększaj
                  </span>
                </a>
                <a
                  href="/poradnik/katar/katar_alergiczny_-_poznaj_i_zapobiegaj"
                  className="box mix cat_1"
                  data-myorder={6}
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_1206612040.jpg")',
                    }}
                  />
                  <span className="name">
                    Katar alergiczny - poznaj i zapobiegaj
                  </span>
                </a>
                <a
                  href="/poradnik/przeziebienie/cztery_fazy_rozwoju_przeziebienia"
                  className="box mix cat_2"
                  data-myorder={7}
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_87834502.jpg")',
                    }}
                  />
                  <span className="name">
                    Cztery fazy rozwoju przeziębienia
                  </span>
                </a>
                <a
                  href="/poradnik/katar/czym_jest_katar"
                  className="box mix cat_1"
                  data-myorder={8}
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_680919733.jpg")',
                    }}
                  />
                  <span className="name">Czym jest katar?</span>
                </a>
                <a
                  href="/poradnik/katar/co_to_jest_zapalenie_zatok"
                  className="box mix cat_1"
                  data-myorder={9}
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_118647259.jpg")',
                    }}
                  />
                  <span className="name">Co to jest zapalenie zatok?</span>
                </a>
                <a
                  href="/poradnik/katar/skutki_niewyleczonego_kataru"
                  className="box mix cat_1"
                  data-myorder={10}
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_754645981.jpg")',
                    }}
                  />
                  <span className="name">Skutki niewyleczonego kataru</span>
                </a>
                <a
                  href="/poradnik/przeziebienie/jak_uchronic_sie_przed_przeziebieniem"
                  className="box mix cat_2"
                  data-myorder={11}
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/88688757.jpg")',
                    }}
                  />
                  <span className="name">
                    Jak uchronić się przed przeziębieniem?
                  </span>
                </a>
                <a
                  href="/poradnik/dzieci/walka_z_infekcjami_-_katar_i_kaszel_u_dziecka_"
                  className="box mix cat_3"
                  data-myorder={12}
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_494479543.jpg")',
                    }}
                  />
                  <span className="name">
                    Walka z infekcjami - katar i kaszel u dziecka{" "}
                  </span>
                </a>
                <a
                  href="/poradnik/katar/pokonaj_zapalenie_zatok"
                  className="box mix cat_1"
                  data-myorder={13}
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_181681637.jpg")',
                    }}
                  />
                  <span className="name">Pokonaj zapalenie zatok</span>
                </a>
              </div>
            </div>
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
